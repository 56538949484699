import {BOOKING_STATUS_DRAFT} from "../../../../constants/common";

export default {
  type: "bookings",
  status: BOOKING_STATUS_DRAFT,
  start_at: null,
  end_at: null,
  adults_count: null,
  children_count: null,
  pets_count: null,
  pricing: null,
  equipment_length: null,
  passenger_extensions: null,
  driver_extensions: null,
  special_request: null,
  comment: null,
  confirmation_expires_at: null,
  relationshipNames: [
    "organization",
    "spot",
    "customer",
  ],
  organization: {
    type: "organizations",
    id: null,
  },
  spot: {
    type: 'spots',
    id: null,
  },
  customer: {
    type: 'customers',
    id: null,
  },
};
